import React from 'react';

import { Link, useNavigate }    from 'react-router-dom';
import { useState, useEffect }  from 'react';
import { OnMainLogoClick, 
         OnMouseEnterNavBar,
         OnMouseLeaveNavBar }   from '../utils/navBarUtils'
import { isOnMobile }           from '../utils/windowUtils'
import axios                    from "axios";

import { getURL, MANGA_INFO, LATEST, ALPHABET_SORT } from '../utils/url';

import '../../CSS/navBar.css'
import home   from '../../SVG/home.svg';
import book   from '../../SVG/book.svg';
import join   from '../../SVG/join.svg';
import random from '../../SVG/random.svg';

const baseURL = getURL();

//http://localhost:3000/mangaReader/manga=1&chapter=116
//http://localhost:3000/mangaReader/manga=1&chapter=116/


let randomnumber;
let maxRandom;
let navBarOptions = [
  { name: "Accueil", link: "#", icon: home, path: "" },
  { name: "Nos séries", link: "#", icon: book, path: "series" },
  { name: "Nous rejoindre", link: "#", icon: join, path: "recrutement" },
  {
    name: "Manga aléatoire",
    link: "#",
    icon: random,
    path: "./mangaReader/manga=" + randomnumber + "&chapter=1",

  },
]
function getRandomNumber(min, max) {

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function createNavOption(option, index) {
  randomnumber = getRandomNumber(1, maxRandom);
  navBarOptions = [
    { name: "Accueil", link: "#", icon: home, path: "" },
    { name: "Nos séries", link: "#", icon: book, path: "series" },
    { name: "Nous rejoindre", link: "#", icon: join, path: "recrutement" },
    {
      name: "Manga aléatoire",
      link: "#",
      icon: random,
      path: "./mangaReader/manga=" + randomnumber + "&chapter=1",

    },
  ]
  return (
    <p key={index}>
      <Link to={"/" + option.path} className="links">
        <img src={option.icon} alt='' className='icons' />
        {option.name}
      </Link>
    </p>
  );
}
function formatAllChapters(seriesObj) {

  let series = [];

  for (let i = 0; i < seriesObj.length; i++) {

    const serieInfo = {
      id: seriesObj[i].MANID,
      name: seriesObj[i].MANNA,
      image: seriesObj[i].frontpage,
      encoding: seriesObj[i].encoding,
      summary: seriesObj[i].MANSU,
      visibility : seriesObj[i].visible
    };

    series.push(serieInfo);
  }

  return series;
}
function createChapterPreviewBanniere(serie, index, setId) {

  return (

      <div className='search-row' key={index}>

        <Link to={"/mangaReader/manga-"+serie.id} className = "lol" onClick={() => {setId(serie.id)}}>
          <img className={'manga-search-img'} src={'data:image/jpg;'+serie.encoding+','+serie.image} alt={serie.name} />
          <h1 className='manga-name-search'>{serie.name}</h1>
        </Link>
      </div>

  );
}
function createChapterInput(serie, index, setId) {

  return (

      <div className='search-row' key={index} tabIndex={index}>

        <Link to={"/series/id="+serie.id} className = "lol" onClick={() => {setId(serie.id)}}>

          <img className={'manga-search-img'} src={'data:image/jpg;'+serie.encoding+','+serie.image} alt={serie.name} />
          <div className="previewElements">
            <h1 className='manga-name-search'>{serie.name}</h1>
            <p className="preview">{serie.summary}</p>
          </div>
        </Link>
      </div>

  );
}


export default function NavBar(props) {
  const root = document.documentElement;
  const [light, setLight] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [post, setPost] = useState(null);
  const [manga_id, setId] = useState(0);
  const [searchString, setSearchString] = useState('');

  const navigate = useNavigate();
  const getAll = async() => {

    let post;
    await axios.get(baseURL+ALPHABET_SORT)
        .then(response => {return response.data})
        .then(response => {post = response})
        .catch(error => {navigate("/NotFound"); return null});
    console.log(post.length)

    maxRandom = post.length;
  }

  const getLatest = async() => {
    randomnumber = getRandomNumber(1, 40);

    await axios.get(baseURL+ALPHABET_SORT)
        .then(response => {return response.data})
        .then(response => {setPost(formatAllChapters(response))})
        .catch(error => {navigate("/NotFound"); return null});
  }





  const trie = (value) => {

    setSearchString(value);

    if(value === ""){
      if (document.querySelector(".manga-searching") === null) return;
      document.querySelectorAll(".manga-searching").forEach((row) => {
      row.style.display = "none"
      });

      value = '%$'
    } else {
      document.querySelectorAll(".manga-searching").forEach((row) => {
        row.style.display = "flex"
      });
    }
    if(document.querySelector(".manga-searching") === undefined || document.querySelector(".manga-searching") === null){
      return;
    }
    const words = value.split(" ");
    document.querySelectorAll(".search").forEach((e) => {
      const rows = e.getElementsByClassName("search-row");
      Array.from(rows).forEach((row) => {
        const titleElement = row.querySelector(".manga-name-search");
        if (
            words.every((word) =>
                titleElement.textContent
                    .toLowerCase()
                    .includes(word.toLowerCase())
            )
        ) {
          row.style.display = "flex";
        } else {
          row.style.display = "none";
        }
      });
    });
  };
  function gintama(event) {
    if (event.key === "Enter") {
      var input = document.getElementById("gintama");

      if (input.value.toLowerCase() == "gintama") {
        window.location = "/gintama";
      } else if (input.value.toLowerCase()== "namiwafwaf") {
        window.location = "/nadgfdgfdgsdrgsgsifgshgishmi";
      } else {
        return
      }
    }
  };
  useEffect(() => {
    const userInput = [];

    window.addEventListener('keyup', (event) => {
      if(event.key == undefined || event.key === null ) return;
      userInput.push(event.key.toLowerCase());
      if (userInput.join('').includes("groud")) {
        document.querySelectorAll('h1, button').forEach((element) => {
            element.innerText = "groud";
        });
      }
    });
    getAll();
    getLatest();

    isOnMobile((result) => {
      setIsMobile(result);
    });

    setTimeout(() => {
      trie("");
    }, 600);

    setInterval(() => {
      if (document.querySelector(".navBar") === null) {
        return
      } else if(document.querySelector(".navBar").style.transform !== `translateY(0%)`) {
        trie("")
      }
    }, 10);

  }, []);

  if (!post) {return null};

  return (

    <div>
        <div style={{position: "fixed", height: "5%" , width: "100%" , opacity: "0", zIndex:'1'}} onMouseEnter={() => OnMouseEnterNavBar(light, isMobile)} onMouseLeave={() => OnMouseLeaveNavBar(light, isMobile)}></div>
        <div className="logo" onClick={() => OnMainLogoClick(isMobile)} onMouseEnter={() => OnMouseEnterNavBar(light, isMobile)} onMouseLeave={() => OnMouseLeaveNavBar(light, isMobile)}></div>

      <div className="navBar" id="liste" onMouseEnter={() => OnMouseEnterNavBar(light)}
           onMouseLeave={() => OnMouseLeaveNavBar(light)}>

        {/* deux fois la même chose */}
        <div className="search" style={{display: isMobile ? 'block' : 'none'}}>
          <div className="group">
            <input value={searchString} onKeyPress={gintama} onChange={(e) => trie(e.target.value)} type="text" required
                   id="gintama" autocomplete="off"></input>
            <span className="highlight"></span>
            <span className="bare"></span>
            <label>Rechercher</label>
          </div>
          <div className="manga-searching">
            {post.map((serie, index) => {
              return createChapterInput(serie, index, setId)
            })}
          </div>
        </div>

        <div className="lien">
          {navBarOptions.map((option, index) => {
            return createNavOption(option, index)
          })}
        </div>

        {/* deux fois la même chose */}
        <div className="search" style={{display: isMobile ? 'none' : 'block'}}>
          <div className="group">
            <input value={searchString} onKeyPress={gintama} onChange={(e) => trie(e.target.value)} type="text" required
                   id="gintama" autocomplete="off"></input>
            <span className="highlight"></span>
            <span className="bare"></span>
            <label>Rechercher</label>
          </div>
          <div className="manga-searching">
            {post.map((serie, index) => {
              return createChapterInput(serie, index, setId)
            })}
          </div>
        </div>

      </div>
    </div>
  );
}
