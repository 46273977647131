import React    from 'react';

import './CSS/index.css';

import NavBar         from './components/homepage/NavBar';
import Credit         from './components/homepage/Footer';
import Recrutement    from './components/recrutement-about/recrutement';
export default function CandidatePage() {
  
    return (
      <div>
        <NavBar  />
          <Recrutement />
        <Credit addOn="home"/>
      </div>
    );
  }
  
  
    

