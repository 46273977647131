import { useState, useEffect} from 'react';
import discordIcon from '../../SVG/discord.svg';
import twitterIcon from '../../SVG/twitter.svg';

import { stylePageTheme } from '../utils/lightUtils'
import { leTheme} from '../utils/lightUtils'

import "../../CSS/footer.css"
import { initializeApp } from 'firebase/app';
import {getDatabase, set, ref, update, get, off} from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCSUQoYmYbmbkKoIbDQcQhOxnada13rBWY",
    authDomain: "memory-c1192.firebaseapp.com",
    projectId: "memory-c1192",
    storageBucket: "memory-c1192.appspot.com",
    messagingSenderId: "320978503728",
    appId: "1:320978503728:web:49c1ef1f72cd9a595745f5",
    measurementId: "G-QP4M89SW8F",
    databaseURL: "https://memory-c1192-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);

function Gintama(props) {
    function incrementValueAtPath() {
        const database = getDatabase(app);
        const dataRef = ref(database, "La page de Nimadoooooos trouvé combien a été trouvé le nombre de fois suivante");

        get(dataRef)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const currentValue = snapshot.val();
                    const newValue = currentValue + 1;
                    return set(dataRef, newValue).then(() => off(dataRef));
                } else {
                    return set(dataRef, 1).then(() => off(dataRef));
                }
            })

            .catch((error) => {
                console.error(`Erreur lors de la mise à jour de la valeur à l'adresse`, error);
            });
    }
    useEffect(() => {

    }, []);

  return (

      <div className="bestAnime" onLoad={() => incrementValueAtPath()}>
          <div className="button" style={{ alignContent: "center", display: "flex", justifyContent: "center", padding: "50px" }}>Notre singe préféré</div>
          <img id="gintoki" src="https://media.discordapp.net/attachments/1012098395697074256/1212174184319422464/image.png?ex=65f0e02e&is=65de6b2e&hm=51eb50e9b6781d271c22680cb6d744dc520551e67557b066f7727a27b52851fa&=&format=webp&quality=lossless&width=170&height=165"/>
          <style jsx="true">{`
              #gintoki {
                  width: 100%;
              }
          `}</style>
      </div>

  );
}

export default Gintama;
