import React              from 'react';
import ReactDOM           from 'react-dom/client';
import { BrowserRouter,
         Route,
         Routes}         from 'react-router-dom';

import MangaReader    from './mangaReader';
import Homepage       from './homepage';
import MangaPage      from './mangaPage';
import NotFoundError  from './errorPage';
import SeriesPage     from './seriesPage';
import CandidatePage  from './candidatePage';
import RandomManga    from './randomManga';
import About          from './aboutPage';

import Gintama  from "./gintamapage";
import Nami  from "./namipage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
    <React.StrictMode>
        <Routes>
          <Route path='/gintama' element={<Gintama />} />
          <Route path='/nadgfdgfdgsdrgsgsifgshgishmi' element={<Nami/>} />

          <Route path="/" element={<Homepage />} />
          <Route path='/series' element={<SeriesPage />} />
          <Route path='/series/:slug' element={<MangaPage />} />
          <Route path="/mangaReader/:slug1/:slug2?" element={<MangaReader/>} />
          <Route path='/recrutement' element={<CandidatePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/random' element={<RandomManga />} />
          <Route path="/NotFound" element={<NotFoundError />} />
        </Routes>
    </React.StrictMode>
</BrowserRouter>
);
