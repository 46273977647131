import { useState, useEffect} from 'react';
import discordIcon from '../../SVG/discord.svg';
import twitterIcon from '../../SVG/twitter.svg';

import { stylePageTheme } from '../utils/lightUtils'
import { leTheme} from '../utils/lightUtils'

import "../../CSS/footer.css"

function Recru(props) {

    useEffect(() => {

    }, []);

    return (
        <div className="container">
            <div className="recrutement">



                <p id="important">Blue Solo est une équipe française de traduction de manga de manière bénévole, fondée en 2020. L'équipe s'évertue à traduire tout type d'œuvres japonaises, dans les uniques buts de les faire découvrir à la communauté française et de combler l'écart de parution.
                    <br/>
                    <br/>
                    Site conçu exclusivement par Blue Solo, avec l'aide des membres suivants :
                    <br/>
                    <br/>
                    Antimone
                    <br/>
                    Chikage
                    <br/>
                    Dirky
                    <br/>
                    Big_herooooo
                    <br/>
                    Lem0nguy</p>


            </div>
            <style jsx>{`

                .nousRejoindre {
                    background: none;
                    border: 2px solid var(--color-000);
                    color: white;
                    padding: 15px 32px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 20px;
                    border-radius: 15px;
                    cursor: pointer;
                    font-weight: bolder;
                }
                .nousRejoindre:hover{
                    background-color: var(--color-000);
                    color: var(--color-BS5);
                    
                }
                .container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: auto!important;
                    padding: 5% 0;
                }

                .recrutement {
                    padding: 40px;
                    border-radius: 15px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
                    max-width: 1500px;
                    width: 80%;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                }
                

                h1 {
                    text-align: center;
                    color:var(--color-000);
                    font-size: 2.5em;
                    margin-bottom: 20px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                }

                h2 {
                    padding-top: -5px;
                    
                    color: var(--color-000);
                    font-size: 1.8em;
                    border-bottom: 2px solid #ccc;
                    padding-bottom: 10px;
                }

                .recrutement > p {
                    line-height: 1.8;
                    margin-bottom: 20px;
                }
                .job {
                    margin-bottom: 40px;
                }
                #important {
                    font-size: 1.5em;
                    font-weight: 500;
                }
                strong{
                    color: var(--color-000);
                }
                @media screen and (max-width: 1090px) {
                    .container {
                        height: unset;
                    }

                    .recrutement {
                        padding: 50px 20px;
                        width: 90%;
                        margin: 20px;
                    }
                }
            `}</style>
        </div>
    );

    ;
}

export default Recru;
