import React from "react";
import { Tooltip } from 'react-tooltip'



import { useState, useEffect }    from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios                      from "axios";
import { getURL, MANGA_INFO_ID } from "../utils/url";
import "../../CSS/lecteur.css";

import List                         from "../seriespage/chapterList";
import {isOnMobile} from "../utils/windowUtils";

const baseURL = getURL();
var enVerti = false;
function previousImage(setIndex, index, isMobile) {
  let div = document.getElementById("lecteur");
  if (index <= 0){
    verifyIfNextChapterOrBeforeExists("chikage t'es le meilleur");
  }
  if (enVerti) {
    return;
    if (index > 0) {
      createLines(index - 1);
      div.childNodes.item(index - 1).scrollIntoView({behavior: 'smooth'});
      setIndex(index - 1);

      let currentURL = window.location.href;
      let newNumber = 2;
      if (currentURL.match(/\/\d+$/)) {
        let match = currentURL.match(/\/(\d+)$/);
        let currentNumber = parseInt(match[1], 10);
        newNumber = currentNumber - 1;
      }
      let newURL = currentURL.replace(/\/\d*$/, '') + '/' + newNumber;
      window.history.pushState({}, '', recupLink());
      window.history.pushState({}, '', newURL);
    }
  } else {
  if (index > 0) {
    createLines(index-1);

    div.childNodes.item(index).classList.remove('visible');
    div.childNodes.item(index).classList.add('hidden');
    div.childNodes.item(index-1).classList.remove('hidden');
    div.childNodes.item(index-1).classList.add('visible');
    setIndex(index-1);
    let currentURL = window.location.href;
    let newNumber = 2;
    if (currentURL.match(/\/\d+$/)) {
      let match = currentURL.match(/\/(\d+)$/);
      let currentNumber = parseInt(match[1], 10);
      newNumber = currentNumber - 1;
    }
    let newURL = currentURL.replace(/\/\d*$/, '') + '/' + newNumber;
    window.history.pushState({}, '', recupLink());
    window.history.pushState({}, '', newURL);
  }
}


}

function nextImage(setIndex, index, Images, isMobile) {
  let div = document.getElementById("lecteur");
  if (Images.length-1 <= index){
    verifyIfNextChapterOrBeforeExists();
  }

  if (enVerti){
    return;

  } else{
    if (index < Images.length-1) {
      createLines(index+1);
      div.childNodes.item(index).classList.remove('visible');
      div.childNodes.item(index).classList.add('hidden');
      div.childNodes.item(index+1).classList.remove('hidden');
      div.childNodes.item(index+1).classList.add('visible');

      setIndex(index+1);
      let currentURL = window.location.href;
      let newNumber = 2;
      if (currentURL.match(/\/\d+$/)) {
        let match = currentURL.match(/\/(\d+)$/);
        let currentNumber = parseInt(match[1], 10);
        currentNumber = 0 ? 1 : currentNumber;
        newNumber = currentNumber + 1;
      }
      let newURL = currentURL.replace(/\/\d*$/, '') + '/' + newNumber;
      window.history.pushState({}, '', recupLink());
      window.history.pushState({}, '', newURL);
    }
  }

}
function displayPageNumber(pageNumber, Images, setIndex) {

  let div = document.getElementById("lecteur");
  let index = pageNumber - 1;
  if (index >= 0 && index < Images.length) {
    createLines(index);
    for (let i = 0; i < Images.length; i++) {
      if (i === index) {
        div.childNodes.item(i).classList.remove('hidden');
        div.childNodes.item(i).classList.add('visible');
      } else {
        div.childNodes.item(i).classList.remove('visible');
        div.childNodes.item(i).classList.add('hidden');
      }
    }

    if (setIndex && typeof setIndex === 'function') {
      setIndex(index);
    }
    let currentURL = window.location.href;
    let newURL = currentURL.replace(/\/\d*$/, '') + '/' + pageNumber;

    if (currentURL === newURL) return;
    window.history.pushState({}, '', recupLink());
    window.history.pushState({}, '', newURL);
  } else {
    console.error("Page number out of range!");
  }
}

function verifyIfNextChapterOrBeforeExists(precedent) {

  const chapterElements = document.querySelectorAll('.theChapters');
  const lien = document.querySelectorAll('.rectangle12 a');
  let currentURL = window.location.href;
  let match = currentURL.match(/chapter=(\d+(?:\.\d+)?)/);
  match =  parseFloat(match[1]);

  for (let i = 0; i < chapterElements.length; i++) {
    const chapterText = chapterElements[i].textContent.trim();
    const chapterNum = parseFloat(chapterText.split(' ')[1].trim());

    if(precedent){

      if (chapterNum === match-0.25 || chapterNum === match-0.5 || chapterNum === match-0.75 || chapterNum === match-1){
        for (let j = 0; j < lien.length; j++) {
          window.location.href = lien[i].href;
        }
        break;

      }
    } else{
      if (chapterNum === match+0.25  || chapterNum === match+0.5 || chapterNum === match+0.75 || chapterNum === match+1) {
        for (let j = 0; j < lien.length; j++) {
          window.location.href = lien[i].href;
        }
        break;
      }
    }


  }


}

function getParameterArray(slug1) {

  const paramterArray = slug1.split("&");
  const paramterArrayValues = [];

  paramterArray.forEach(element => {
    paramterArrayValues.push(element.split("=")[1])
  });

  return paramterArrayValues;

}
function createLines(position, total) {
  const tube = document.getElementById('tube');

  tube.querySelectorAll('.horizontal-line, .bar').forEach(element => {
    element.remove();
  });

  let totalLines = total;
  if (total === undefined) {
    totalLines = document.querySelector("#lecteur").querySelectorAll('img').length;
  }

  tube.style.setProperty('--total-lines', totalLines);

  const distanceBetweenLines = tube.clientWidth / totalLines;

  for (let i = 0; i < totalLines; i++) {
    const line = document.createElement('div');
    line.classList.add('horizontal-line');
    line.style.left = `${i * distanceBetweenLines}px`;
    line.style.width = `${distanceBetweenLines}px`; // Add this line to set the width
    line.setAttribute('data-number', i + 1);
    tube.appendChild(line);

    if (i < position + 1) {
      line.style.backgroundColor = '#00d0ff';
    }
    if (i === position){
      line.style.opacity = '1';
    }

    if (i !== totalLines - 1) {
      const bar = document.createElement('div');
      bar.classList.add('bar');
      bar.style.left = `${(i + 1) * distanceBetweenLines - 1}px`;
      tube.appendChild(bar);
    }
  }
}
function recupLink(){
  let siteURL = window.location.origin;

  let lienOriginal = window.location.href;

  let regex = /manga=(\d+)/;
  let match = lienOriginal.match(regex);

  if (match) {
    let idDuManga = match[1];
    let nouveauLien = siteURL + "/series/id=" + idDuManga;
    return nouveauLien;

  } else {
    console.log("Impossible de trouver l'ID du manga dans le lien original.");
  }
}

function setStorage(key, value) {
  localStorage.setItem(key, value);
}

function bloquerScroll(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}
function bloquerScrollKeys(e) {
  if (e.keyCode === 32 || e.keyCode === 33 || e.keyCode === 34 || e.keyCode === 35 || e.keyCode === 36 || e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
    e.preventDefault();
    return false;
  }
}

export default function ImageCore() {
  const [isMobile, setIsMobile] = useState(false);
  const [isVerti, setIsVerti] = useState(false);
  const [images, setImages] = useState(null);
  const [index, setIndex] = useState(0);
  const [post, setPost] = useState(null);
  const navigate = useNavigate();

  const { slug1, slug2 } = useParams();

  function scrollToTop() {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 0) {
      if(lecteur.style.flexDirection == "column") return;
      const newScrollY = (currentScrollY/1.05) - 2;
      window.scrollTo(0, newScrollY);
      setTimeout(scrollToTop, 1);
    }
  }
  var uneDesCasesaEteClique = false;

  function handleScroll() {
    if (!enVerti) return;

    const lecteur = document.querySelector("#lecteur");
    if (!lecteur) return;
    if (uneDesCasesaEteClique === true) return;
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    const scrollPosition = window.scrollY;

    const numberOfImages = lecteur.querySelectorAll('img').length;
    const sectionHeight = totalHeight / numberOfImages;

    const section = Math.floor(scrollPosition / sectionHeight) + 1;

    displayPageNumber(section, images, setIndex);

    createListener();
  }

  function createSuiviHauteurPage() {
    window.addEventListener("scroll", handleScroll);
  }


  let clickHandler = function(event) {

    if (event.target.matches('.horizontal-line')) {
      const line = event.target;
      const index = parseInt(line.dataset.number, 10);
      uneDesCasesaEteClique = true;
      displayPageNumber(index, images, setIndex);
      const div = document.querySelector(`.imgContainer[data-index="${index-1}"]`)
      // Si la div est trouvée, faites défiler la page jusqu'à cette div
      if (div) {
        div.scrollIntoView({ behavior: 'smooth' });
      }
      const lecteur = document.querySelector("#lecteur");
      lecteur.childNodes.forEach((child, i) => {
        console.log("mooooooooo")
        if (i === index - 1) {
          child.classList.remove('hidden');
          child.classList.add('visible');
        } else {
          child.classList.remove('visible');
          child.classList.add('hidden');
        }
      });

      createLines(index - 1);
      createListener();

       uneDesCasesaEteClique = false;

    }
  };

  var lecteur = document.querySelector("#lecteur");
  const handleClick = (event) => {
    lecteur = document.querySelector("#lecteur");
    if (event.type === 'keydown' && event.code === 'ArrowLeft') {
      previousImage(setIndex, index, isMobile);
      scrollToTop();
    } else if (event.type === 'keydown' && event.code === 'ArrowRight') {
      nextImage(setIndex, index, images, isMobile);

      scrollToTop();
    } else if (event.clientX < lecteur.getBoundingClientRect().left + lecteur.offsetWidth / 2) {
      previousImage(setIndex, index, images, isMobile);
      scrollToTop();

    } else if(event.clientX < lecteur.getBoundingClientRect().right + lecteur.offsetWidth / 2) {
      nextImage(setIndex, index, images, isMobile);
      scrollToTop();
    }
    createListener();
  };
  var existingClickEvent = false;

  function createListener() {
    window.addEventListener('popstate', popstate);
    document.querySelectorAll(".horizontal-line").forEach(line => {
      line.removeEventListener('click', clickHandler);
      line.addEventListener('click', clickHandler);
    });

  }
  let popstate = function(event){

  console.log('Changement dans l\'historique !');
};


  const getImages = async() => {
    const parameters = getParameterArray(slug1);
    let id = "";
    let chaid = "";
    let query = "";

    switch (parameters.length) {

      case 1:
        id = parseInt(parameters[0]);
        if (Number.isNaN(id)) {navigate("/NotFound"); return null};
        query = "id="+parameters[0];
        break;

      case 2:
        id = parseInt(parameters[0]);
        chaid = parseInt(parameters[1]);
        if (Number.isNaN(id) || Number.isNaN(chaid)) {navigate("/NotFound"); return null}
        query = "id="+parameters[0]+"&chapter="+parameters[1];

        break;

      default:
        query = "id=0";
        break;

    }

    await axios.get(baseURL+"images?"+query)
    .then(response => {return response.data})
    .then(response => {if (response.type == "400" || response.type == "500") {navigate("/NotFound"); return null} else setImages(response)})
    .catch(error => {navigate("/NotFound"); return null});

  }
  const getLatest = async() => {

    const id = slug1.split("=")[1];
    let param_id = parseInt(id);
    if (Number.isNaN(param_id)) {navigate("/NotFound"); return null}

    await axios.get(baseURL+MANGA_INFO_ID+id)
        .then(response => {return response.data})
        .then(response => {if (response.type == "400") {navigate("/NotFound"); return null} else setPost(response)})
        .catch(error => {navigate("/NotFound"); return null});
  }


  function zoomOnImage(event) {
    let value = document.querySelector("#bareDeZoom").value;
    if (event > 0){
      value = event;
      document.querySelector("#bareDeZoom").value = event;
    }
    var PageVisible = document.querySelectorAll(".imgContainer");
    PageVisible.forEach((page, i) => {
        page.style.height = (100 + (value*1.25)) + "vh";
    });
    if (value.length === 1)
      document.querySelector('#output').innerHTML = "  " + value + "%";
    else if (value.length === 2)
      document.querySelector('#output').innerHTML = " " + value + "%";
    else if (value.length === 3)
      document.querySelector('#output').innerHTML = value + "%";
    checkOverlap()
    setStorage("zoom", value);
  }

  function checkOverlap() {
    var letrucAvecLesChap = document.getElementById('chapters');
    var pages = document.querySelectorAll(".imgContainer");

    var rect1 = letrucAvecLesChap.getBoundingClientRect();
    pages.forEach(page => {
        var rect2 = page.getBoundingClientRect();
        if (
            rect1.top < rect2.bottom &&
            rect1.bottom > rect2.top &&
            rect1.left < rect2.right &&
            rect1.right > rect2.left
        ) {
            mooveChapterList("SUUUU");
        }

    })

  }
  function lecteurcharger() {

    if (localStorage.getItem('verti') === 'true') {
      vertical();
    }
    let pageNum = "";
    pageNum = parseInt(slug2);
    if (pageNum <= images.length) {
      displayPageNumber(pageNum, images, setIndex);
      const div = document.querySelector(`.imgContainer[data-index="${pageNum-1}"]`)

      if (div) {
        div.scrollIntoView({ behavior: 'smooth' });
      }
      createListener();
    } else if(pageNum > images.length){
      displayPageNumber(1, images, setIndex);
      createListener();
    }

    let zoomVar = localStorage.getItem('zoom')
    if (isMobile) return;
    zoomOnImage(zoomVar);
  }
  useEffect(() => {
    isOnMobile((result) => {
      setIsMobile(result);
    });

    getImages();
    getLatest();
  }, []);

  useEffect(() => {
    let affichage = document.querySelector("#pageAfficheur")
    if(!affichage) return;
    affichage.innerHTML = index + 1;
    affichage.classList.remove("apparition");
    setTimeout(() => {
      affichage.classList.add("apparition");
    }, 100);
    mooveChapterList("le top 3 de mes anime, GINTAMA, clannad et monogatari");
  }, [index]);

  if (!post) {return null};

  const informations = [
    {title:"Titre", content: post.manga_info.name},
    {title:"Type", content: post.manga_info.type},
    {title:"Magazine", content: post.manga_info.magazine},
    {title:"Visibilité", content: post.manga_info.visible}
  ]

  function toggleFullScreen() {
    var docEl = document.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen;

    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      requestFullScreen.call(docEl);

      document.querySelector(".cacher > :nth-child(1)").style.opacity = "0.35";
      document.querySelector(".cacher > :nth-child(2)").style.opacity = "0.35";
      document.querySelector(".cacher > :nth-child(3)").style.opacity = "0.35";
      document.querySelector("#tube").style.opacity = "0.20";
      document.querySelectorAll(".logo").forEach(logo => { logo.style.opacity = "0.35" });

    } else {
      cancelFullScreen.call(document);
      document.querySelector(".cacher > :nth-child(1)").style.opacity = "1";
      document.querySelector(".cacher > :nth-child(2)").style.opacity = "1";
      document.querySelector(".cacher > :nth-child(3)").style.opacity = "1";
      document.querySelector("#tube").style.opacity = "0.40";
      document.querySelectorAll(".logo").forEach(logo => { logo.style.opacity = "1" });
    }
  }

  var mangalink = recupLink();
  function mooveChapterList(cacher){


  }
  function mooveChapterList(cacher){
    if (isMobile){

      let menu = document.querySelector("#chapters");
      let fleche = document.querySelector("#fleche2");
      if (menu.style.transform === "translate(-300%, -50%)"){
        if(cacher === "SUUUU" || cacher === "le top 3 de mes anime, GINTAMA, clannad et monogatari") return;
        menu.style.transform = 'translate(-50%, -50%)';
        fleche.style.transform = 'rotateY(0deg)';
        document.querySelector(".option").classList.toggle("endessous");
        document.querySelector(".option").classList.toggle("audessus");
        createblureffect("j'ai eu arlecchino c1 r1");
      } else{
        menu.style.transform = 'translate(-300%, -50%)';
        fleche.style.transform = 'rotateY(180deg)';
        setTimeout(() => {
          document.querySelector(".option").classList.toggle("audessus");
          document.querySelector(".option").classList.toggle("endessous");
        }, 100);
        createblureffect()
      }
    } else {

      let menus = document.querySelectorAll("#chapters");
      let menu;
      if (menus[0].style.display === "none"){
        menu = menus[1];
      } else{
        menu = menus[0];
      }
      console.log(menus)
      let fleche = document.querySelector("#fleche2");
      if (menu.style.transform === "translateX(-250%)") {
        if (cacher === "SUUUU" || cacher === "le top 3 de mes anime, GINTAMA, clannad et monogatari") return;
        menu.style.transform = 'translateX(0%)';
        fleche.style.transform = 'rotateY(0deg)';
        document.querySelector(".option").classList.toggle("endessous");
        document.querySelector(".option").classList.toggle("audessus");
      } else {
        menu.style.transform = 'translateX(-250%)';
        fleche.style.transform = 'rotateY(180deg)';
        setTimeout(() => {
          document.querySelector(".option").classList.toggle("audessus");
          document.querySelector(".option").classList.toggle("endessous");
        }, 100);
      }
    }
  }
  function createblureffect(verif){
    let blurScreen = document.querySelector("#fond");
    if (verif == "j'ai eu arlecchino c1 r1"){
      blurScreen.style.display = "block"
      for (let i = 0; i <= 50; i++) {
        setTimeout(() => {
          blurScreen.style.opacity = i*2 + "%";
        }, i * 10);
        blurScreen.addEventListener('DOMMouseScroll', bloquerScroll, false);
        blurScreen.addEventListener('wheel', bloquerScroll, { passive: false });
        blurScreen.addEventListener('mousewheel', bloquerScroll, false);
        blurScreen.addEventListener('touchmove', bloquerScroll, { passive: false });
        blurScreen.addEventListener('keydown', bloquerScrollKeys, false);
        document.body.style.overflowY = "hidden"
      }
    } else{
      let j = 1;
      for (let i = 25; i >= 0; i--) {
        j++;
        setTimeout(() => {
          blurScreen.style.opacity = i*4 + "%";
         }, j * 8);

        }
      blurScreen.style.display = `none` + ' !important'
      blurScreen.removeEventListener('DOMMouseScroll', bloquerScroll, false);
      blurScreen.removeEventListener('wheel', bloquerScroll, { passive: false });
      blurScreen.removeEventListener('mousewheel', bloquerScroll, false);
      blurScreen.removeEventListener('touchmove', bloquerScroll, { passive: false });
      blurScreen.removeEventListener('keydown', bloquerScrollKeys, false);
      document.body.style.overflowY = "auto";

    }
  }

  const html = document.querySelector('html');

  function parametre(){
    let para = document.querySelector(".overlay");

    if (para.style.display === "undefined" || para.style.display === "null" || para.style.display === ""){
        para.style.display = "none";
    }
    if (para.style.display == "none"){
      para.style.display = "unset";
      document.addEventListener('DOMMouseScroll', bloquerScroll, false);
      document.addEventListener('wheel', bloquerScroll, { passive: false });
      document.addEventListener('mousewheel', bloquerScroll, false);
      document.addEventListener('touchmove', bloquerScroll, { passive: false });
      document.addEventListener('keydown', bloquerScrollKeys, false);



    } else{
      para.style.display = "none";
    }
  }
  function fermer(event) {
    let para = document.querySelector(".overlay");
    let content = document.querySelector(".content");
    if (!content.contains(event.target)) {
      if (para.style.display !== "none") {
        para.style.display = "none";
          document.removeEventListener('DOMMouseScroll', bloquerScroll, false);
          document.removeEventListener('wheel', bloquerScroll, { passive: false });
          document.removeEventListener('mousewheel', bloquerScroll, false);
          document.removeEventListener('touchmove', bloquerScroll, { passive: false });
          document.removeEventListener('keydown', bloquerScrollKeys, false);

        console.log("daaar")
      }
    }
  }

  function removeEventListenerIfExists(eventName, handler, options) {
    if (document.removeEventListener) {
      document.removeEventListener(eventName, handler, options);
    } else if (document.detachEvent) {
      // Pour les anciens navigateurs IE
      document.detachEvent('on' + eventName, handler);
    }
  }

    function horizontal() {
      lecteur = document.querySelector("#lecteur");
      const verti = document.querySelector("#verti");
      const hori = document.querySelector("#hori");
      enVerti = false;
      setStorage("verti", enVerti);
      setIsVerti(false);
      let horiP = hori.querySelector("svg path ")
      let vertiP = verti.querySelector("svg path ")
      if(!hori.classList.contains("select")){
        hori.classList.add("select");
        verti.classList.remove("select");
        horiP.style.fill = "var(--color-BS5)";
        vertiP.style.fill = "var(--color-BS1)";
      };
      lecteur.style.flexDirection = "row";
      lecteur.style.width = "100%";
      lecteur.childNodes.forEach((child, i) => {
        child.classList.remove('absVisible');
      });

    }
  function vertical() {
    lecteur = document.querySelector("#lecteur");
    const verti = document.querySelector("#verti");
    const hori = document.querySelector("#hori");
    enVerti = true;
    setStorage("verti", enVerti);
    setIsVerti(true);
    let horiP = hori.querySelector("svg path ")
    let vertiP = verti.querySelector("svg path ")
    if(!verti.classList.contains("select")){
        verti.classList.add("select");
        hori.classList.remove("select");
      horiP.style.fill = "var(--color-BS1)";
      vertiP.style.fill = "var(--color-BS5)";
    };

    lecteur.style.flexDirection = "column";
    lecteur.style.width = "auto";
    lecteur.childNodes.forEach((child, i) => {
        child.classList.add('absVisible');
    });
    let div = document.querySelector(`.imgContainer[data-index="${index}"]`)
    if (div) div.scrollIntoView();

  }
  function créationZoomBar(){
    const elements = document.querySelectorAll(['range-slider']);

    elements.forEach(element => {
      element.insertAdjacentHTML('afterend', `
    <output>${element.value}</output>
  `);
    });

  }

  if (!images) {return null};

  return (

      <div className="pageLect" tabIndex="0"
           onKeyDown={e => handleClick(e)}>
        <div id="lecteur"

             onLoad={() => {
               createLines(index, images.length);
               createListener();
               createSuiviHauteurPage();
               lecteurcharger();
             }}
             onKeyDown={e => handleClick(e)}
             onClick={
               handleClick

             }
        >


          {images.map((link, index) => (
              <img
                  key={index}
                  src={link}
                  className={`imgContainer ${index === 0 ? 'visible' : 'hidden'}`}
                  data-index={index}
                  alt={`Page ${index + 1}`}
                  style={{height: isMobile ? 'unset' : ' '}}
              />
          ))}
          <div id='fond'></div>
        </div>
        <div className="overlay" onClick={fermer}>
          <div className="content">
            <div className="flex-container">
              <h1>SENS DE LECTURE</h1>
              <div className="sub-container">
                <div className="element select" id="hori" onClick={vertical}>Lecture Verticales
                  <svg className="FlecheDeSens" version="1.1" width="16" height="16" viewBox="0 0 16 16">
                    <path fill="var(--color-BS5)" d="M9 3h2l-3-3-3 3h2v10h-2l3 3 3-3h-2z"></path>
                  </svg>
                </div>
                <div className="element" id="verti" onClick={horizontal}>Lecture Horizontale
                  <svg className="FlecheDeSens" version="1.1"
                       width="16" height="16" viewBox="0 0 16 16">
                    <path fill="var(--color-BS1)" d="M16 8l-3-3v2h-10v-2l-3 3 3 3v-2h10v2z"></path>
                  </svg>
                </div>
              </div>
              <div className="sub-container" id="zoomContainer" style={{display: isMobile ? 'none' : 'flex'}}>
                <div
                    className="element select" id="zoomLevel">Niveau de Zoom :
                </div>

                <range-slider onMouseMove={zoomOnImage} onMouseUp={zoomOnImage} onMouseDown={zoomOnImage}
                              onMouseEnter={zoomOnImage} onMouseLeave={zoomOnImage} onLoad={créationZoomBar} min="0"
                              value="0" max="100" step="10" dir="rtl" id='bareDeZoom'></range-slider>
                <output id='output'>0%</output>


              </div>
            </div>
          </div>
        </div>
        <div id="chapters" className="chapters2" style={{transform: window.innerWidth < 1090 ? 'translate(-300%,-50%)' : '', display: isMobile ? 'block' : 'none'}}>
          <a href={mangalink}>
            <div className="back">
              <span id="fleche">ᐸ</span>
              <div>
                <p>{informations[0].content}</p>
              </div>
            </div>
          </a>
          <List data={post} manga_id={slug1.split("=")[1]}/>
        </div>
        <div className={`option ${isVerti ? 'audessus' : 'endessous'}`}>

          <div className="cacher">
            <svg onClick={parametre} fill="var(--color-BS1)" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                 viewBox="0 0 24 24">
              <path
                  d="M24 13.616v-3.232c-1.651-.587-2.693-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.749-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.743-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 3.384c-2.762 0-5-2.239-5-5s2.238-5 5-5 5 2.239 5 5-2.238 5-5 5zm3-5c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3z"/>
            </svg>
            <svg id="fleche2" onClick={mooveChapterList} fill="var(--color-BS1)" width="30px"
                 height="30px"
                 viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M21,11H9.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H21a1,1,0,0,0,0-2ZM3,3A1,1,0,0,0,2,4V20a1,1,0,0,0,2,0V4A1,1,0,0,0,3,3Z"/>
            </svg>
            <i className="fas fa-expand" data-tooltip-id="VIDE" data-tooltip-content="Pleine écran"
               onClick={toggleFullScreen}></i>

            <Tooltip class="__react_component_tooltip" id='myTooltip' place='right' style={{background: "red"}}
                     effect='solid' title="delete">
            </Tooltip>
          </div>
          <div id="chapters"  style={{
            transform: window.innerWidth < 1090 ? 'translate(-300%,-50%)' : '',
            display: isMobile ? 'none' : 'block'
          }}>
            <a href={mangalink}>
              <div className="back">
                <span id="fleche">ᐸ</span>
                <div>
                  <p>{informations[0].content}</p>
                </div>
              </div>
            </a>
            <List data={post} manga_id={slug1.split("=")[1]}/>
          </div>

        </div>


        <div id="tube"
             onKeyDown={e => handleClick(e)}>
        </div>
        <div id="pageAfficheur"></div>
        <style jsx='true'>{`

          .rectangle11 {
            display: flex;
            position: relative;
            padding: 0;
            height: 50vh;
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            background: none;
            flex-direction: column;
          }

          .button {
            padding: 13px 25px 13px 25px;
            border-radius: 4px;
            border: none;
            width: 300px;
          }
          .chapters2 .button{
            width: auto!important;
            margin-right: 0px!important;
          }


          .cacher > :nth-child(1):hover {
            opacity: 1 !important;
            transition: all 0.3s ease-out !important;
            filter: drop-shadow(4px 5px 2px var(--color-1000));
          }

          .cacher > :nth-child(2):hover {
            opacity: 1 !important;
            transition: all 0.3s ease-out !important;
            filter: drop-shadow(4px 5px 2px var(--color-1000));
          }

          .cacher > :nth-child(3):hover {
            opacity: 1 !important;
            transition: all 0.3s ease-out !important;
            filter: drop-shadow(4px 5px 2px var(--color-1000));
          }

          #zoomLevel {
            background-color: var(--color-000);
            color: var(--color-1000);
            min-width: fit-content;
            cursor: default;
          }

          #output {
            font-weight: 600;
            width: 60px;
          }

          .endessous {
            /*z-index: 1 !important;*/
          }

          .audessus {
            z-index: 10 !important;
          }

          .thumb {
            background-color: var(--color-BS4);
          }

          range-slider:before {
            background: linear-gradient(var(--color-BS3), var(--color-BS4)) 0 / var(--value-percent, 0) 100% no-repeat var(--color-300);
          }

          range-slider:focus .thumb {
            box-shadow: 0 0 0 0em var(--color-BS1);
          }

          @media screen and (max-width: 1090px) {

            .imgContainer {
              left: 0;
              height: unset;
              max-width: 100%;
            }

            .pageLect .option {
              top: 10vh;
              left: 5vw;
              scale: 0.9;
            }

            #lecteur {
              width: 100% !important;
              //top: 25%;
              z-index: 9;
              margin: 17vh 0;
            }
            #fond{
              position: fixed;
              width: 100vw;
              height: 100%;
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              z-index: 9;
              opacity: 0;
              display: none;
            }
            #fond::after{
              content: " ";
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0.8;
              background-color: var(--color-BS5);
            }
            #chapters {
              z-index: 98;
              left: 50%;
              top: 50%;
              transform: translate(-300%, -50%); 
              position: fixed;
            }
            .back{
              color: var(--color-000);
              font-weight: 800;
            }
            #fleche2 {
              transform: rotateY(180deg);
            }

            .cacher {
              justify-content: end;
            }

            body {
              overflow: auto;
            }


          }
        `}</style>

      </div>
  );

}
			