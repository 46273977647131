import React from 'react';

import { useState, useEffect } from 'react';
import { Link, useNavigate }    from 'react-router-dom';
import { stylePageTheme } from '../utils/lightUtils'
import { leTheme }        from '../utils/lightUtils'
import { isOnMobile }           from '../utils/windowUtils'
import "../../CSS/footer.css"
import discordIcon from '../../SVG/discord.svg';
import twitterIcon from '../../SVG/twitter.svg';

function Credit(props) {
  const root = document.documentElement;
  const [light, setLight] = useState(true);
  const [isBas, setIsBas] = useState(false);
  const [aVoir, setAVoir] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
    var targetNode = document.body;

// Options pour le MutationObserver (specify which mutations to observe)
    var config = { attributes: true, subtree: true, attributeFilter: ['style'] }

// Fonction de rappel à exécuter lorsque des mutations sont observées
    var callback = function (mutationsList, observer) {
        for (var mutation of mutationsList) {
            if (mutation.type === 'childList' || mutation.type === 'attributes' || mutation.attributeName === 'style') {
                // Vérifiez chaque nœud ajouté
                if(mutation) {
                    positionnerFooter();
                    setTimeout(() => {
                        positionnerFooter();
                    }, 200);
                }

            }
        }
    };

// Créez une instance de MutationObserver avec la fonction de rappel
    var observer = new MutationObserver(callback);

    function handleResize() {
        positionnerFooter();

    }
    function createCredit() {
        const creditElement = document.querySelector(".credit");
        if (creditElement) {
            creditElement.style.display = "flex";
        }
    }
    function positionnerFooter() {
            createCredit();
        if (document.documentElement.scrollHeight  > window.innerHeight) {
           // console.log("bas", document.documentElement.scrollHeight, window.innerHeight)
            setIsBas(false)
        } else if(isMobile === true){
            setIsBas(true)
        } else {
           // console.log("bas", document.documentElement.scrollHeight, window.innerHeight)
            window.location.href.includes("mangaReader") ? setIsBas(false) : setIsBas(true)
        }
    }
    useEffect(() => {
        observer.observe(targetNode, config);
        window.addEventListener('resize', handleResize);

        isOnMobile((result) => {
            setIsMobile(result);
        });



        function handleScroll() {
            const windowHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const bodyHeight = document.body.clientHeight;
            const scrollPosition = window.scrollY;
        }

        window.addEventListener('scroll', handleScroll);


        function tryCode() {
            try {
                if (leTheme !== undefined) {

                        if (leTheme.id === 0) {
                            document.querySelector("#dn").checked = false;
                            stylePageTheme(root, light, props.addOn);
                            setLight(false);
                        } else {
                            document.querySelector("#dn").checked = true;
                            stylePageTheme(root, !light, props.addOn);
                            setLight(true);
                        }

                } else {
                    document.querySelector("#dn").checked = true;
                }
            } catch (error) {
                console.error("Une erreur s'est produite :", error);
                setTimeout(tryCode, 10);
            }
        }

        let currentURL = window.location.href;

        if (currentURL.includes("mangaReader")) {
            setAVoir(false);
        } else{
            setAVoir(true);
        }
        function setStorage(key, value) {
            localStorage.setItem(key, value);
        }
        VerifyColorTheme();
        function VerifyColorTheme() {
            let letheme = localStorage.getItem('theme')
            try {
                if (letheme !== undefined) {

                    if (letheme === "light") {
                        document.querySelector("#dn").checked = false;
                        stylePageTheme(root, light, props.addOn);
                        setLight(false);
                    } else {
                        document.querySelector("#dn").checked = true;
                        stylePageTheme(root, !light, props.addOn);
                        setLight(true);
                    }

                } else {
                    document.querySelector("#dn").checked = true;
                }
            } catch (error) {
                setTimeout(VerifyColorTheme, 10);
            }
        }
    }, []);
        function setStorage(key, value) {
            localStorage.setItem(key, value);
        }
    function SetColorTheme() {
        let letheme = localStorage.getItem('theme')
        try {
            if (letheme === "dark") {
                setLight(!light);
                stylePageTheme(root, light, props.addOn);
                setStorage('theme', 'light')

            } else {
                setLight(!light);
                stylePageTheme(root, light, props.addOn);
                setStorage('theme', 'dark')
            }
        } catch (error) {
            console.error("Une erreur s'est produite :", error);
        }
    }

  return (

    <div className={`credit ${isBas ? 'bas' : ''} ${aVoir ? '' : 'invisible'}`}>
      <div className='gauche'>
        <p>BlueSolo © 2023</p>
        <div className="toggleWrapper">
            <input type="checkbox" className="dn" id="dn"  onClick={() => { setLight(!light); SetColorTheme(); stylePageTheme(root, light, props.addOn); }} />
            <label htmlFor="dn" className="toggle">
              <span className="toggle__handler">
                <span className="crater crater--1" ></span>
                <span className="crater crater--2"></span>
                <span className="crater crater--3"></span>
              </span>
              <span className="star star--1"></span>
              <span className="star star--2"></span>
              <span className="star star--3"></span>
              <span className="star star--4"></span>
              <span className="star star--5"></span>
              <span className="star star--6"></span>
            </label>
          </div>
        </div>
        <div className="plus">
          <Link to={"/about"} className="links">
          <p>About</p>
           </Link>
          <a href="https://discord.gg/JzV6cSBazX" target="_blank" rel="noreferrer">
            <img src={discordIcon} alt="discord" className="discord" />
          </a>
          <a href="https://twitter.com/BlueSoloScans" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="twitter" className="twitter" />
          </a>
        </div>

        <style jsx="true">{`
          .credit {
            display: none;
            background-color: var(--color-BS5);
            height: 63px;
            font-size: 17px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            color: var(--color-000);
              
          }
          .credit p{
            font-size: 17px;
            
          }
          .credit.bas {
            position: absolute;
            bottom: 0;
          }
          .plus {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 3vw;
            margin-right: 5%;
          }

          .plus p{
            cursor: pointer;
          }
          .plus p{
             cursor: pointer;
           }
          .invisible{
            display: none!important;
          }
        `}</style>
      
    </div>
  );
}

export default Credit;
