import React from 'react';

import { Link } from "react-router-dom";

import "../../CSS/chapterList.css"

const formatChapterNumber = (chapter) => {

    if (chapter === undefined) return 0
    else return chapter;
}

function createChapter(manga_id, chapter, visibility) {
    let realName = chapter.name.split("Chapitre")[1];
    const currentURL = window.location.href;

    if (visibility != 1) return;

    let linkContent;
    if (currentURL.includes("series")) {
        linkContent = (
            <div className="button">
                <p className="theChapters">{chapter.number}</p>
                <p>{realName}</p>
            </div>
        );
    } else {
        linkContent = (
            <div className="button">
                <p className="theChapters">{chapter.number}</p>
                <p>{realName}</p>
            </div>
        );
    }

    return (
        <>
            {currentURL.includes("series") ? (
                <Link to={`/mangaReader/manga=${manga_id}&chapter=${formatChapterNumber(chapter.number.split(" ")[1])}`}>
                    {linkContent}
                </Link>
            ) : (
                <Link
                    to={`/mangaReader/manga=${manga_id}=${formatChapterNumber(chapter.number.split(" ")[1])}`}
                    onClick={() =>
                        setTimeout(() => {
                            let urlToFind = formatChapterNumber(chapter.number.split(" ")[1])
                            let lienOriginal = currentURL;
                            let regex = lienOriginal.match(/chapter=(\d+(?:\.\d+)?)/);
                            let idDuManga = regex[1];

                            if (urlToFind !== idDuManga) {
                                window.location.reload();
                            }
                        }, 10)
                    }
                >
                    {linkContent}
                </Link>

            )}
        </>
    );
}


export default function chapterList(props) {
    let visibility = props.data.manga_info.visible;
    //props = props.chapters;
    //  console.log(props)
    return(


        <div className="rectangle11">
            <div className="rectangle12">
                {Object.values(props.data.chapters).slice(0, -1).map((chapter) => {return createChapter(props.manga_id, chapter, visibility)})}
            </div>
        </div>


    );
}