import { useState, useEffect} from 'react';

import "../../CSS/footer.css"
import { initializeApp } from 'firebase/app';
import { getDatabase, set, off, ref, update, get } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCSUQoYmYbmbkKoIbDQcQhOxnada13rBWY",
    authDomain: "memory-c1192.firebaseapp.com",
    projectId: "memory-c1192",
    storageBucket: "memory-c1192.appspot.com",
    messagingSenderId: "320978503728",
    appId: "1:320978503728:web:49c1ef1f72cd9a595745f5",
    measurementId: "G-QP4M89SW8F",
    databaseURL: "https://memory-c1192-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);

function Gintama(props) {
    function incrementValueAtPath() {
        const database = getDatabase(app);
        const dataRef = ref(database, "LE MEILLEUR ANIME TROUVÉ !");

        get(dataRef)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const currentValue = snapshot.val();
                    const newValue = currentValue + 1;
                    return set(dataRef, newValue).then(() => off(dataRef));
                } else {
                    return set(dataRef, 1).then(() => off(dataRef));
                }
            })

            .catch((error) => {
                console.error(`Erreur lors de la mise à jour de la valeur à l'adresse`, error);
            });
    }
    useEffect(() => {

    }, []);

  return (

      <div className="bestAnime" onLoad={() => incrementValueAtPath()}>
          <div className="button" style={{ alignContent: "center", display: "flex", justifyContent: "center", padding: "50px" }}>Bravo pour l'avoir trouvé hehe</div>
          <img id="gintoki" src="https://images2.alphacoders.com/835/835088.png"/>
          <h1>MEILLEUR ANIME DE TOUT LES TEMPS</h1>
          <h2>MEILLEUR ANIME DE TOUT LES TEMPS</h2>
          <p>MEILLEUR ANIME DE TOUT LES TEMPS</p>
          <h3>MEILLEUR ANIME DE TOUT LES TEMPS</h3>
          <h4>MEILLEUR ANIME DE TOUT LES TEMPS</h4>
          <h5>MEILLEUR ANIME DE TOUT LES TEMPS</h5>
          <h6>MEILLEUR ANIME DE TOUT LES TEMPS</h6>
          <style jsx="true">{`
              #gintoki {
                  width: 100%;
              }
          `}</style>
      </div>

  );
}

export default Gintama;
