export function isOnMobile(setIsMobile) {

   let handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };
   handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}
export function addMouseMenuListener() {
    document.addEventListener("mousemove", function(event) {
        var mouseY = event.clientY;

        if (mouseY <= 10) {
            console.log("Vous êtes en haut de la page");
        } else {
            console.log("Vous êtes en bas de la page");
        }
    });
}