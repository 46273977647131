import React    from 'react';

import './CSS/index.css';

import NavBar         from './components/homepage/NavBar';
import Credit         from './components/homepage/Footer';
import Nami from "./components/Gintama/Nami";
export default function CandidatePage() {

    return (
        <div>
            <NavBar  />
            <Nami />
            <Credit addOn="home"/>
        </div>
    );
}



