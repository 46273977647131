import { useState, useEffect} from 'react';
import discordIcon from '../../SVG/discord.svg';
import twitterIcon from '../../SVG/twitter.svg';

import { stylePageTheme } from '../utils/lightUtils'
import { leTheme} from '../utils/lightUtils'

import "../../CSS/footer.css"

function Recru(props) {

    useEffect(() => {

    }, []);

    return (
        <div className="container">
            <div className="recrutement">
                <h1> Recrutement en cours !</h1>
                <p>Dans l’optique d’étendre nos activités tout en continuant de vous proposer du contenu de qualité,
                    l’équipe recrute activement aux postes suivants :</p>

                <div className="job">
                    <h2><i className="fas fa-language" id="trad" style={{
                        fontSize: '32px',
                        bottom: '-2px',
                        position: 'relative'
                    }}></i> Traducteur</h2>
                    <p>Première étape dans chaque projet de scantrad, le traducteur traduit et adapte le texte d’un
                        manga ou d’un manhwa en français. L’important ici n’est pas de faire du mot pour mot, mais de
                        restituer le sens de la manière la plus naturelle possible. Il est donc important d’avoir un bon
                        niveau dans la langue traduite, mais également en français, le tout pour retranscrire au mieux
                        le texte d’origine.</p>
                </div>

                <div className="job">
                    <h2><i className="fas fa-check-square"></i> Checkeur</h2>
                    <p>Rôle discret mais pourtant essentiel, le checkeur repasse sur les traductions afin de corriger
                        les fautes d’orthographe, de grammaire, de conjugaison ou de ponctuation. Il lui arrive aussi de
                        suggérer certaines reformulations au traducteur afin d’obtenir un texte de qualité optimale. Le
                        checkeur se doit donc d’avoir un excellent niveau en langue française.</p>
                </div>

                <div className="job">
                    <h2><i className="fas fa-paint-brush"></i> Éditeur</h2>
                    <p>L’éditeur s’occupe de la mise en page de la traduction. Multitâche, il doit placer les textes
                        dans les bulles au bon endroit et avec la bonne forme, jongler entre les différentes polices
                        d’écriture et parfois reconstruire les dessins de la page originale. Aucun prérequis pour
                        postuler si ce n’est de posséder le logiciel Adobe Photoshop; la team se fera en effet un
                        plaisir de vous former aux spécificités du poste.</p>
                </div>

                <p id="important">Vous êtes intéressé(e) ? Rejoignez-nous sur notre serveur Discord et envoyez un
                    message
                    à <strong>mlemmmm</strong> sur discord !</p>

                <a href="https://discord.gg/JzV6cSBazX" target="_blank" >
                    <button className="nousRejoindre">Nous rejoindre !</button>
                </a>


            </div>
            <style jsx>{`

                .nousRejoindre{
                    background: none;
                    border: 2px solid var(--color-000);
                    color: white;
                    padding: 15px 75px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 20px;
                    border-radius: 15px;
                    cursor: pointer;
                    font-weight: bolder;
                    color: var(--color-000);
                }
                .nousRejoindre:hover{
                    background-color: var(--color-000);
                    color: var(--color-BS5);
                    
                }
                .container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: auto!important;
                    padding: 5% 0;
                }

                .recrutement {
                    padding: 40px;
                    border-radius: 15px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
                    max-width: 1500px;
                    width: 80%;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                }
                

                h1 {
                    text-align: center;
                    color:var(--color-000);
                    font-size: 2.5em;
                    margin-bottom: 20px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                }

                h2 {
                    padding-top: -5px;
                    
                    color: var(--color-000);
                    font-size: 1.8em;
                    border-bottom: 2px solid var(--color-200);
                    padding-bottom: 10px;
                }
                .recrutement > p {
                    line-height: 1.8;
                    margin-bottom: 20px;
                }
                .job {
                    margin-bottom: 40px;
                }
                .job p{
                    color: var(--color-000);
                    padding-top: 11px;
                    font-size: 19px;
                    line-height: 1.5;
                }
                #important {
                    font-size: 1.5em;
                    font-weight: 500;
                }
                strong{
                    color: var(--color-000);
                }
                .fas {
                    color: var(--color-000);
                }
                @media screen and (max-width: 1090px) {
                    .container {
                        height: unset;
                    }

                    .recrutement {
                        padding: 50px 20px;
                        width: 90%;
                        margin: 20px;
                    }
                }
            `}</style>
        </div>
    );

    ;
}

export default Recru;
