import React from 'react';

import { useRef, useState, useEffect }  from "react";
import {Link, useNavigate }                  from "react-router-dom";
import axios                            from "axios";
import Flickity                         from "flickity";

import { getURL, EXPLORE } from '../utils/url';

import "../../CSS/slideShow.css";
import "flickity/css/flickity.css";
import {OnMouseEnterNavBar} from "../utils/navBarUtils";

// choose so that the js lib run after all is rendered
const TIMEOUT = 1;

const baseURL = getURL();

function stackSlideShowPanels(setIsVisible, flickityRef) {
  const MAX_RETRIES = 600;
  const MAX_RETRIES2 = 7;
  let retries = 0;
  let retries2 = 0;
  function createFlickity() {
    const carouselElement = document.querySelector(".carousel");

    if (!carouselElement) {
      if (retries < MAX_RETRIES) {
        retries++;
        setTimeout(createFlickity, 50);
        return;
      } else {
        console.log("Échec de la création de Flickity : l'élément .carousel est introuvable.");
        return;
      }
    }

    setIsVisible(true);

    if (flickityRef.current) {
      flickityRef.current.destroy();
    }

    flickityRef.current = new Flickity(carouselElement, {
      prevNextButtons: false,
      accessibility: true,
      wrapAround: true,
     percentPosition: false,
      autoPlay: 4000,
      pageDots: "li",
    });

    setTimeout(() => {
      document.querySelectorAll('.carousel-cell-2').forEach(cell => { cell.style.opacity = "1" });
      document.querySelectorAll('.flickity-page-dot').forEach(cell => {  cell.style.transition = "opacity 0.5s, background 0.7s, color 0.25s"; });

    }, 0);
    let rapide = document.querySelector(".flickity-slider").style.transform;
    if (rapide !== "translateX(0%)" && rapide !== "translateX(0px)") {
      if (retries2 < MAX_RETRIES2) {
        rapide = document.querySelector(".flickity-slider").style.transform;
        retries2++;
        setTimeout(() => {
          createFlickity();

        }, 100);
        return;
      } else {
        console.log("Échec de la création de Flickity : l'élément .carousel est cassé.");
        document.querySelectorAll('.carousel-cell-2').forEach(cell => { cell.style.width = "100.70%" });
        document.querySelectorAll('.carousel-cell').forEach(cell => { cell.style.width = "100.70%" });

        return;
      }

    }
    //transition: opacity 0.5s, background 0.7s, color 0.25s;

  }

  createFlickity();

  return () => {
    if (flickityRef.current) {
      flickityRef.current.destroy();
    }
  };


}


function createPanel(id, manga, index) {
  function transformHTML() {
    return;
    document.querySelectorAll('.principal').forEach(element => {

      const text = element.innerText.trim();
      const letters = text.split('');

      const newContent = letters.map((letter, index) => `<span style="--char-index: ${index + 1};">${letter}</span>`).join('');

      element.innerHTML = newContent;
    });

  }

  let tags = Object.values(manga.tags).slice(0, -1);

  return (
      <div className={"carousel-cell"+id} key={index}>
        <div className="info">

          <p class="decouverte">Découverte</p>
          <h1 className="text principal"   >{manga.name}</h1>
          <div className="tag">
            {tags.map(tag => <p className="text">{tag}</p>)}
          </div>

          <div className="summary">
            <p className="summaryContent" >{manga.summary}</p>
          </div>

          <div className="end">
            <Link to={"/series/id="+manga.id}>
              <button>Lire</button>
            </Link>
            <p className="latestChapter">{manga.latest} • </p>
            <p className="text">{manga.status}</p>
          </div>

        </div>
        <img onLoad={transformHTML} className="slideImage"
             src={'data:image/jpg;'+"base64" + ','+manga.image}

             alt="preview" />

      </div>
  );
}

function SlideShow () {

  const [isVisible, setIsVisible] = useState(false);
  const [post, setPost] = useState(null);

  const flickityRef = useRef(null);
  const navigate = useNavigate();

  const getExplore = async() => {

    await axios.get(baseURL+EXPLORE)
        .then(response => {return response.data})
        .then(response => {setPost(response)})
        .catch(error => {navigate("/NotFound"); return null});
  }

  useEffect(() => {
    getExplore();
    stackSlideShowPanels(setIsVisible, flickityRef);
  }, []);


  if (!post) {return null};

  return (

      <div className="carousel">

        {post.map((manga, index) => (
            createPanel((index === 0) ? "" : "-2", manga, index)
        ))}

      </div>

  );
}

export default SlideShow;