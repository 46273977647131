import React    from 'react';

import './CSS/index.css';

import NavBar         from './components/homepage/NavBar';
import Credit         from './components/homepage/Footer';
import Gintama from "./components/Gintama/Gintama";
export default function CandidatePage() {

    return (
        <div>
            <NavBar  />
            <Gintama />
            <Credit addOn="home"/>
        </div>
    );
}



